<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">消息管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">公告推送</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">发布公告</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a" style="padding-right: 60px">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="11rem"
              class
            >
              <el-form-item
                label="标题名称"
                prop="noticeTitle"
                class="form-item"
              >
                <el-input
                  v-model="ruleForm.noticeTitle"
                  maxlength="50"
                  :show-word-limit="true"
                  :disabled="ruleForm.id ? true : false"
                  placeholder="请输入标题名称"
                />
              </el-form-item>
              <el-form-item
                label="公告内容"
                prop="noticeDetail"
                class="form-item"
              >
                <div ref="editor" class="editor" style="width: 100%" />
              </el-form-item>
              <el-form-item label="上传文件" prop="attachmentUrl">
                <div class="seeStyle">
                  <el-upload
                    v-model="ruleForm.fileName"
                    v-if="!ruleForm.fileName"
                    class="senPDf"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button size="small" class="bgc-bv"
                      >上传PDF文件</el-button
                    >
                  </el-upload>
                  <span style="color: #f46173" v-else>
                    {{ ruleForm.fileName }}
                    <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                  </span>
                  <span style="color: #f46173" v-if="seeUrl">
                    <a @click="seeFun" style="color: #5bb5ff; margin-left: 20px"
                      >预览</a
                    >
                  </span>
                </div>
              </el-form-item>
              <!-- <el-form-item label="角色名称" prop="roleIds" class="form-item">
                <el-cascader
                  v-model="ruleForm.roleIds"
                  :options="realRoleList"
                  :props="propsarea"
                  clearable
                  style="width:100%"
                  placeholder="请选择角色名称"
                ></el-cascader>
              </el-form-item>-->
              <el-form-item label="角色名称" prop="roleIds">
                <el-select
                  multiple
                  placeholder="请选择角色名称"
                  clearable
                  v-model="ruleForm.roleIds"
                >
                  <template slot="prefix">
                    <el-tooltip content="全选" placement="top-start">
                      <i
                        class="el-icon-finished check-all-btn"
                        @click="checkAllFiles"
                      ></i>
                    </el-tooltip>
                  </template>
                  <el-option
                    v-for="item in realRoleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="行政区划" prop="areaIds" class="form-item">
                <el-cascader
                  :options="areatreeList"
                  :props="propsarea"
                  clearable
                  filterable
                  v-model="ruleForm.areaIds"
                  placeholder="请选择行政区划"
                  style="width: 100%"
                ></el-cascader>
              </el-form-item>
              <!-- @change="changeAll" -->
              <!-- <el-form-item label="行政区划" prop="roleIds">
                <el-select placeholder="请选择行政区划" multiple clearable v-model="ruleForm.areaIds">
                  <template slot="prefix">
                    <el-tooltip content="全选" placement="top-start">
                      <i class="el-icon-finished check-all-btn" @click="checkAllarea"></i>
                    </el-tooltip>
                  </template>
                  <el-option
                    v-for="item in areatreeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>-->
              <el-form-item
                label="公告类型"
                prop="noticeType"
                class="form-item"
              >
                <el-select
                  v-model="ruleForm.noticeType"
                  clearable
                  placeholder="请选择公告类型"
                >
                  <el-option
                    v-for="item in noticeTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-show="userJson.roleId == 1 && item.value != 40"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否设置首次登录推荐" prop="recommend">
                <el-switch v-model="ruleForm.recommend"></el-switch>
              </el-form-item>
              <el-form-item
                label="弹窗设置"
                prop="frequency"
                v-if="ruleForm.recommend"
              >
                <el-select size="small" v-model="ruleForm.frequency">
                  <el-option value="1" label="仅弹一次"></el-option>
                  <el-option value="2" label="登录即弹"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="推荐时间"
                prop="startTime"
                v-if="ruleForm.recommend"
                style="width: 500px"
              >
                <el-date-picker
                  clearable
                  size="small"
                  v-model="ruleForm.startTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-form>
            <div class="pageForm-btns">
              <el-button @click="onCancel" class="bgc-bv">取消</el-button>
              <el-button class="bgc-bv" @click="submit">确定</el-button>
            </div>
          </div>
        </div>
        <el-dialog
          title="pdf预览"
          :visible.sync="dialogCert"
          width="50%"
          center
          :before-close="closeDownloadCert"
        >
          <div id="pdf-cert" style="height: 800px"></div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Form from "@/mixins/Form";
import E from "wangeditor";

import pdf from "pdfobject";
let editor = {};
let updataTimer = null;
export default {
  name: "AnnouncementPushEdit",
  components: {},
  mixins: [Form],
  data() {
    return {
      dialogCert: false,
      seeUrl: "",
      realRoleName: {},
      eWidth: "",
      areatreeList: [], //行政区域列表
      realRoleList: [], //角色名称列表
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      // 页面数据
      ruleForm: {
        updateId: "",
        noticeTitle: "", //标题
        noticeDetail: "", //富文本
        attachmentUrl: "", //附件地址
        roleIds: [],
        areaIds: [],
        noticeType: "", // 公告类型
        recommend: false, // 是否设置首次登录推荐
        startTime: [], // 推荐时间
        frequency: "1",
      },
      //行政规划的值
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      // 公告类型下拉数据
      noticeTypes: [],
      // 数据校验
      rules: {
        noticeTitle: [
          { required: true, message: "请输入标题名称", trigger: "blur" },
        ],
        noticeDetail: [
          { required: true, message: "请输入公告内容", trigger: "change" },
        ],
        attachmentUrl: [
          { required: false, message: "请上传PDF文件", trigger: "change" },
        ],
        roleIds: [
          { required: true, message: "请选择角色名称", trigger: "change" },
        ],
        areaIds: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        noticeType: [
          { required: true, message: "请选择公告类型", trigger: "change" },
        ],
        recommend: [
          {
            required: true,
            message: "请选择是否设置首次登录推荐",
            trigger: "change",
          },
        ],
        startTime: [
          { required: true, message: "请选择推荐时间", trigger: "change" },
        ],
        frequency: [
          { required: true, message: "请选择弹窗设置", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  watch: {
    "ruleForm.noticeDetail": function (val) {
      if (val) {
        this.$refs["ruleForm"].clearValidate("noticeDetail");
      }
    },
    "ruleForm.attachmentUrl": function (val) {
      if (val) {
        this.$refs["ruleForm"].clearValidate(["attachmentUrl"]);
      }
    },
  },
  created() {},
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
    this.getareatree();
    this.getrealRoleList();
    this.getNoticeTypes();
    if (this.$route.query.id == "0") {
      this.ruleForm.updateId = "";
      this.$refs["ruleForm"].resetFields();
    } else {
      this.ruleForm.updateId = this.$route.query.id;
      this.$refs["ruleForm"].resetFields();
      this.getDetail(this.ruleForm.updateId);
    }
  },
  methods: {
    onCancel() {
      this.$router.push("/web/AnnouncementPush");
    },
    seeFun() {
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(this.seeUrl, "#pdf-cert");
      });
    },
    // 调取字典 - 获取公告类型下拉数据
    getNoticeTypes() {
      const classstatuslist = this.$setDictionary("NOTICETYPE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.noticeTypes = list;
    },
    //获取角色列表
    getrealRoleList() {
      this.$post("/sys/per/role/list").then((ret) => {
        let arr = ret.data;
        arr.forEach((el, i) => {
          el.value = el.id;
          el.label = el.name;
        });
        this.realRoleList = ret.data;
      });
    },
    //角色名称全选
    checkAllFiles() {
      this.ruleForm.roleIds = [];
      this.realRoleList.forEach((file) => this.ruleForm.roleIds.push(file.id));
    },
    // changeAll() {
    //   if (this.ruleForm.areaIds == 1) {
    //     this.ruleForm.areaIds = [];
    //     this.areatreeList.forEach(file =>
    //     this.ruleForm.areaIds.push(file.value)
    //     )
    //   }
    // },
    // },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
        // const newObj = { value: 1, label: "全部" };
        // this.areatreeList.unshift(newObj);
      });
    },
    //提交
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.attachmentUrl = result.data.fileKey;
          this.seeUrl = result.data.fileURL;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    getLast(data) {
      let arr = [];
      data.forEach((el, i) => {
        if (el.length > 0) {
          let num = el.length;
          arr.push(el[num - 1]);
        } else {
          arr.push("");
        }
      });
      return arr;
    },
    submit() {
      const el = this.$refs["ruleForm"];
      el.validate((valid) => {
        if (valid) {
          //行政区划处理
          let url = "";
          if (this.ruleForm.updateId == "") {
            url = "/notice/insert";
          } else {
            url = "/notice/modifyNotice";
          }
          if (this.ruleForm.recommend) {
            this.ruleForm.recommendStartDate = this.ruleForm.startTime[0];
            this.ruleForm.recommendEndDate = this.ruleForm.startTime[1];
            this.ruleForm.frequency = this.ruleForm.frequency;
          } else {
            if (
              this.ruleForm.hasOwnProperty("recommendStartDate") ||
              this.ruleForm.hasOwnProperty("recommendEndDate")
            ) {
              delete this.ruleForm.recommendStartDate;
              delete this.ruleForm.recommendEndDate;
            }
          }
          this.$post(url, { ...this.ruleForm }).then((result) => {
            if (result.status === "0") {
              this.$message({
                type: "success",
                message: "发布成功",
              });
              this.$router.push({
                path: "/web/AnnouncementPush",
                query: {
                  refresh: true,
                },
              });
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    getDetail(noticeId) {
      this.$post("/notice/getNoticeInfo", { noticeId: noticeId }).then(
        (result) => {
          if (result.status === "0") {
            let data = result.data;
            this.ruleForm.roleIds = data.roleIds;
            this.ruleForm.areaIds = data.areaIds;
            this.ruleForm.attachmentUrl = data.attachmentUrl;
            this.ruleForm.noticeDetail = data.noticeDetail;
            this.ruleForm.noticeId = data.noticeId;
            this.ruleForm.noticeTitle = data.noticeTitle;
            this.ruleForm.noticeType = data.noticeType;
            editor.txt.html(data.noticeDetail);
            this.seeUrl = data.previewUrl;
            this.ruleForm.recommend = data.recommend;
            this.ruleForm.frequency = data.frequency || '1';
            if (data.recommend) {
              this.ruleForm.startTime = [
                data.recommendStartDate.replace(/\//g, "-"),
                data.recommendEndDate.replace(/\//g, "-"),
              ];
            }
          }
        }
      );
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        'indent', // 缩进
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
        'lineHeight',
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      // editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = false;
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.noticeDetail = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
  },
  // 拖动按钮
  // beforeDestroy: function() {
  //   if (updataTimer) {
  //     clearTimeout(updataTimer);
  //   }
  // }
};
</script>
<style lang="less">
.el-switch__core:after {
  background-color: #fff;
}
.seeStyle {
  display: flex;
  flex-direction: row;
}
.pageForm-btns {
  padding: 15px 0;
  text-align: center;
  .el-button {
    width: 10rem;
    span {
      min-width: 4em;
    }
  }
}
.senPDf {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.addtestPaper {
  .el-input--small .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.check-all-btn {
  font-size: 22px;
  line-height: inherit;
  color: #409eff;
  cursor: pointer;
}
.check-all-btn:hover {
  color: #66b1ff;
}
</style>